import apiClient from "@/services/ApiClient";

export function mapActions(serviceName) {
  return {
    name() {
      return serviceName;
    },
    getServicesList() {
      return apiClient.get("/servicios-asignacion");
    },
    getAgentsList() {
      return apiClient.get("/tecnicos/list");
    },
    updateServiceData(id, data) {
      return apiClient.put(`/servicios-asignacion/${id}`, data);
    },
    updateAgentsOrder(data) {
      return apiClient.put(`/servicios-asignacion/prioridad`, data);
    },
  };
}
