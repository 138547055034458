import { crudActions } from "@/services/CrudActions";
import apiClient from "@/services/ApiClient";

export default {
  ...crudActions("partes-comandas"),
  getAgentsList(id = undefined) {
    let path = "/tecnicos/list";
    if (id) path += `?parte_id=${id}`;
    return apiClient.get(path);
  },
  getArticles(id = undefined) {
    let path = "/article-families/list";
    if (id) path += `?parte_id=${id}`;
    return apiClient.get(path);
  },
  getPdf(ids) {
    return apiClient.get(`/presupuestos/${ids}/pdf`);
  },
  getQuoteAmount(id) {
    return apiClient.get(`/presupuestos/${id}/precio`);
  },
  sendEmail(id) {
    return apiClient.post(`/presupuestos/${id}/send-email`);
  },
};
